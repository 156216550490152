import React from 'react';
import "./Portfolio.css";
import images from '../../../Assets/Images/images';

function Portfolio() {
  return (
    <div className='portfolio-container' id='portfolio-container'>
      <div className='portfolio-content'>
       
        <div className='portfolio-text'>
        <div className='border-orange'></div>
        <h2>Portfolyo</h2>

          <div className='ref-box  references-box'>
            <img src={images.portfolipEnterprise} alt="Portfolyo-Referans" />
            <div className='ref-text references-text'>
              <h3>Referanslarımız</h3>
              <p>Firmamız, otomobil tamir’i sektöründe uzun yıllardır güvenilir bir hizmet sunmaktadır. Ali Bostancı İnşaat, Ay Kardeşler Mermer, Üçel Kauçuk, Narin Kauçuk, Sesler Elektirik, RBR Otomotiv ve Çimsan Kauçuk gibi birçok önde gelen firma, araç bakım ve onarımlarında bizimle çalışmaktadır. Müşterilerimizin güvenliği ve performansı bizim önceliğimizdir.</p>
            </div>
          </div>

          <div className='ref-box mission-box'>
            <img src={images.portfolioLeader} alt="Portfolyo-Misyon & Vizyon" />
            <div className='ref-text mission-text'>
              <h3>Misyon & Vizyon</h3>
              <p>Avcı Oto ailesi olarak, müşterilerimize kaliteli, güvenilir ve özelleştirilmiş bir hizmet sunarak, araçlarının güvenliği ve performansını en üst düzeye çıkarmayı hedefleriz. Yaptığımız işlerin başarıları ve müşteri memnuniyeti, bizim için en önemli referans kaynaklarındandır.</p>
            </div>
          </div>
        </div>

        <div className='portfolio-img'>
          <img src={images.portfolioLego} alt="Portfolyo-Lego" />
        </div>

      </div>
    </div>
  )
}

export default Portfolio