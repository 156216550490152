import React from 'react'
import "./About.css";
import images from '../../../Assets/Images/images';

function About() {
  return (
    <div className='about-container' id='about-container'>
      <div className='about-content'>
        <div className='about-img-box'>
          <img src={images.aboutKeysImg} alt="keys-Img" />
        </div>

        <div className='about-text-box'>
          <div className='border-orange'></div>
          <h2>Hakkımızda</h2>
          <p>Firmamız, 1996 yılından bu yana Bursa'da otomobil tamir ve bakım hizmeti sunmaktadır. Uzman teknisyenlerimiz, yılların verdiği tecrübe ve bilgi birikimi ile her türlü sorunu çözebilmektedir.</p>
          <p>Müşterilerimiz, bizim için her zaman önceliklidir. Dürüstlük ve şeffaflık ilkelerini benimseyerek, her müşterimizin ihtiyaçlarına özenle yaklaşıyoruz. Aracınızın bakımı ve onarımı konusunda en iyi hizmeti sunmak için elimizden gelenin en iyisini yapıyoruz.</p>
          <p>Firmamızın amacı, müşterilerimizin güvenini kazanarak, en kaliteli ve güvenilir otomobil tamir ve bakım hizmetini sunmaktır. Eğer siz de aracınız için profesyonel ve güvenilir bir hizmet arıyorsanız, bizimle iletişime geçebilirsiniz.</p>
        </div>
      </div>
    </div>
  )
}

export default About