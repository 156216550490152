import React from 'react'
import "./Customer.css"
import GoogleMap from '../../Function/GoogleMap';



function Customer() {
  const center = { lat: 40.20856622469631, lng: 28.946414683308276 }; // Example coordinates
  const zoom = 17;
 
  return (
    <div className='customer-container' id='customer-container'>
      <div className='customer-content'>
        <h1>MÜŞTERİLERİMİZDEN GELEN YORUMLAR</h1>
        <div className='customer-box-container'>
          <GoogleMap center={center} zoom={zoom} />
        </div>

      </div>
    </div>
  )
}

export default Customer