/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import "./Contact.css";
import HoverableDiv from '../../Function/HoverableDiv';
import images from '../../../Assets/Images/images';


function Contact() {

  return (
    <div className='contact-container' id='contact-container'>
      <div className='contact-content'>

        <div className='iframe-container'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190.43948319557882!2d28.94635274955593!3d40.208357101063356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca11e8b6fb3d73%3A0x94af38d0c1300f02!2sAvc%C4%B1oto%20(Mustafa%20Avc%C4%B1)!5e0!3m2!1str!2str!4v1721203240142!5m2!1str!2str" loading="lazy"></iframe>
        </div>

        <div className='box-container '>
          <HoverableDiv className="box box-border-one" lightImg={images.callIcon} darkImg={images.callIconDark}>
            <h4>BİZE ULAŞIN</h4>
            <p>+90 534 505 29 44</p>
          </HoverableDiv>

          <HoverableDiv className="box" lightImg={images.emailIcon} darkImg={images.emailIconDark}>
            <h4>EMAİL GÖNDERİN</h4>
            <p>avci_oto@hotmail.com</p>
          </HoverableDiv>

          <HoverableDiv className="box box-border-two" lightImg={images.locationIcon} darkImg={images.locationIconDark}>
            <h4>ADRESİMİZ</h4>
            <p>Üçevler Küçük sanayi Sitesi 13.Sokak 15.Blok No:58 BURSA / NİLÜFER</p>
          </HoverableDiv>

        </div>


      </div>
    </div>
  )
}

export default Contact