import React, { useState } from 'react';
import images from '../../Assets/Images/images';



function ClickableDiv({ children, className, boxHeader, boxDetail }) {
    const [clickCheck, setClickCheck] = useState(false)

    function boxClick() {
        setClickCheck(!clickCheck)
    }
    
    return (
        <div
            onClick={boxClick}
            className={className}>

            {children}

            <div className='drop-box-header-btn'>
                <p>{boxHeader}</p>
                <img className={!clickCheck ? 'plus-icon-deg' : 'plus-icon '} src={images.openPlus} alt="plus-iconu" />
            </div>

            <div className={!clickCheck ? 'drop-box-detail-close' : 'drop-box-detail-open'}>
                <p>{boxDetail}</p>
            </div>

        </div>
    )
}

export default ClickableDiv