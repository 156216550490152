import React, { useState } from 'react'
import images from '../../Assets/Images/images';


function GoogleReviews({ reviews }) {
    const [readMoreList, setReadMoreList] = useState(reviews.map(() => false));

    function readMoreClick(index) {
        const newReadMoreList = [...readMoreList];
        newReadMoreList[index] = !newReadMoreList[index];
        setReadMoreList(newReadMoreList);
    }
    
    
    const renderReviews = () => {
        return reviews.map((review, index) => (
            <div className='box' key={index} >
                <div className='customer-photo'><img src={review.profile_photo_url} alt="profile" />
                </div>
                <h3>{review.author_name}</h3>
                <p className={!readMoreList[index] ? 'min-review' : 'full-review'}>{review.text}</p>
                <button onClick={() => readMoreClick(index)} className='read-more-btn'>
                    {readMoreList[index] ? 'Daha Az Göster' : 'Devamını Oku'}
                </button>
                <div className='puan-star'>
                    {[...Array(review.rating)].map((_, index) => (
                        <img key={index} src={images.customerStar} alt="star" />
                    ))}
                </div>
            </div>
        ));
    };

    return (
        <>
            {reviews.length > 0 && renderReviews()}
        </>
    )
}

export default GoogleReviews
