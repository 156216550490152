import React from 'react';
import "./Our_features.css";
import images from '../../../Assets/Images/images';


function Our_features() {
    return (
        <div className='our_features-container' id='our_features-container'>
            <div className='our_fetaures-content'>

                <h1>ÖZELLİKLERİMİZ</h1>
                <p>Avcı oto ailesi olarak, tamirat ihtiyacı duyan otomobil sahiplerinin güvenle tercih edebilecekleri bir firmadır.</p>

                <div className='features-img-container'>

                    <div className='features-box features-box-one'>
                        <div className='before-text'>
                            <p className='before-header'>Müşteri memnuniyeti odaklı çalışma prensibimizle</p>
                            <hr />
                            <p>Her müşterimizin ihtiyacına uygun çözümler sunarak otomobil tamiratlarını sorunsuz bir şekilde gerçekleştiriyoruz</p>
                        </div>
                    </div>

                    <div className='features-box features-box-two'>
                        <div className='before-text'>
                            <p className='before-header'>Güler yüzlü, samimi ve dürüst yaklaşımımız sayesinde müşterilerimizin bizi tercih etmeleri için birçok sebep var</p>
                            <hr />
                            <p>İşini hakkıyla yapan, en son teknolojilere hakim ve bunları kullanabilen uzman ekibimiz, her türlü arıza durumunda nokta atışı tespit yaparak sorununuzu kısa sürede çözüme kavuşturur</p>
                        </div>
                    </div>

                    <div className='features-box features-box-three'>
                        <div className='before-text'>
                            <p className='before-header'>Tamirat sürecinde müşterilerimize en kaliteli hizmeti sunabilmek için son teknolojik ekipmanlarımızı kullanıyoruz</p>
                            <hr />
                            <p>Çözüm odaklı yaklaşımımız sayesinde, her müşterimizin ihtiyacına uygun tamirat çözümleri sunuyoruz</p>
                        </div>
                    </div>

                    <div className='features-box features-box-four'>
                        <div className='before-text'>
                            <p className='before-header'>Siz de aracınızda oluşan arızalar için bizimle iletişime geçebilir</p>
                            <hr />
                            <p>Güvenilir ve kaliteli bir hizmet almanın keyfini çıkarabilirsiniz</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Our_features