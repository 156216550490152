import "./Our_services.css";
import ClickableDiv from '../../Function/ClickableDiv';
import WebContext from "../../../Context/WebSideContext";
import { useContext } from "react";

function Our_services() {
  const data = useContext(WebContext)
  console.log(data);

  return (
    <div className='our_services-container' id='our_services-container'>
      <div className='our_services-content'>
        <h1>HİZMETLERİMİZ</h1>
        <p>Avcı Oto ailesi olarak verdiğimiz hizmetlerin detaylarını buradan inceleyebilirsiniz</p>
        <div className='dropBox-container'>
          <ClickableDiv id="box-plus-one" className='drop-box drop-one' boxHeader={"Fren Bakımı"} boxDetail={"Fren sistemi aracın güvenliği için son derece önemlidir. Frenlerin çalışmasını kontrol ederek gerekli durumlarda fren balatalarını değiştiriyoruz."} />

          <ClickableDiv id="box-plus-two" className='drop-box drop-two' boxHeader={"Motor Revizyonu"} boxDetail={"Motor, aracın kalbidir. Motor revizyonu hizmetimiz ile motorunuzun ömrünü uzatmak ve performansını artırmak için gerekli bakım ve onarımları gerçekleştiriyoruz."} />

          <ClickableDiv id="box-plus-three" className='drop-box drop-three' boxHeader={"Yağ ve Filtre Bakımı"} boxDetail={"Motor yağı ve filtreleri, aracın düzenli olarak değiştirilmesi gereken önemli parçalarındandır. Yağ ve filtre bakımı hizmetimiz ile aracınızın performansını artırabilir ve motorun ömrünü uzatabilirsiniz."} />

          <ClickableDiv id="box-plus-four" className='drop-box drop-four' boxHeader={"Ön Takım Tamir ve Revizyonu"} boxDetail={"Aracın ön takımı, sürüş güvenliği ve konforu için son derece önemlidir. Ön takım tamir ve revizyonu hizmetimiz ile aracınızın direksiyon, süspansiyon, tekerlek ve lastiklerindeki sorunları çözüyoruz"} />

          <ClickableDiv id="box-plus-five" className='drop-box drop-five' boxHeader={"Arıza Tespit"} boxDetail={"Arıza tespit cihazları, aracınızdaki sorunları belirlememize yardımcı olur. Bu hizmetimiz ile aracınızın tüm sistemlerini tarayarak nokta atışı hata bulma yaparız."} />

          <ClickableDiv id="box-plus-six" className='drop-box drop-six' boxHeader={"Periyodik Bakım"} boxDetail={"Triger seti değiştirme, otomobilin performansını ve güvenliğini artırmak için önemli işlemlerdir. Bu işlemler, aracın ömrünü uzatır ve araç sahibinin cebindeki parayı da korur. Ancak, bu işlemler profesyonel bir mekanik tarafından yapılmalı ve orijinal yedek parça kullanılmalıdır."} />
        </div>
      </div>
    </div>
  )
}

export default Our_services