import "./App.css";
import HomeWeb from "./Pages/Web_layout/Home/Home";
import { GoogleMapsProvider } from "./Context/GoogleMapsContext";

function App() {
  return (
    <div className="App">
      <GoogleMapsProvider>
        <HomeWeb />
      </GoogleMapsProvider>
    </div>
  );
}

export default App;
