
import "./Header.css";
import images from '../../../Assets/Images/images';
import HoverableDiv from '../../Function/HoverableDiv';

function Header() {

  return (
    <div className='header-container' id='header-container'>
      <div className='header-content'>

        <div className='header-text'>
          <h1>AVCI OTO</h1>
          <h3>OTOMOBİL TAMİR İSTASYONU</h3>
          <p>Yolculuğunuzdaki Güven</p>
        </div>

        <div className='header-box-container'>
          <div className='header-box-content'>
            <div className='box-container'>

              <HoverableDiv className="box box-border-one" lightImg={images.brake} darkImg={images.brakeDark}>
                <h4>Fren Bakımı</h4>
                <p>Fren sistemi aracın güvenliği için son derece önemlidir.</p>
              </HoverableDiv>
              <HoverableDiv className="box" lightImg={images.engine} darkImg={images.engineDark}>
                <h4>Motor Revizyonu</h4>
                <p>Motor, aracın kalbidir.</p>
              </HoverableDiv>
              <HoverableDiv className="box" lightImg={images.funnel} darkImg={images.funnelDark}>
                <h4>Yağ ve Filtre Bakımı</h4>
                <p>Motor yağı ve filtreleri, aracın düzenli olarak değiştirilmesi gereken önemli parçalarındandır.</p>
              </HoverableDiv>
              <HoverableDiv className="box" lightImg={images.tire} darkImg={images.tireDark}>
                <h4>Ön Takım Tamir ve Revizyonu</h4>
                <p>Aracın ön takımı, sürüş güvenliği ve konforu için son derece önemlidir.</p>
              </HoverableDiv>
              <HoverableDiv className="box" lightImg={images.fault} darkImg={images.faultDark}>
                <h4>Arıza Tespit</h4>
                <p>Arıza tespit cihazları, aracınızdaki sorunları belirlememize yardımcı olur</p>
              </HoverableDiv>
              <HoverableDiv className="box box-border-two" lightImg={images.strap} darkImg={images.strapDark}>
                <h4>Periyodik Bakım</h4>
                <p>Triger seti değiştirme, otomobilin performansını ve güvenliğini artırmak için önemli işlemlerdir.</p>
              </HoverableDiv>

            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default Header