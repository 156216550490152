import "./Navbar.css"
import images from "../../../Assets/Images/images";
import { Link } from 'react-scroll';
import { useState } from "react";
function Navbar() {
  const [navDropClick, setNavDropClick] = useState(false)


  const navBarOnClick = () => {
        setNavDropClick(!navDropClick)
  }

console.log(navDropClick);
  return (
    <div className='navbar-container' id="navbar-container">
      <div className='navbar-content'>

        <div className='logo-container'>
          <img src={images.logo} alt="avci-oto-logo" />
        </div>

        <div className="link-container">

          <div className="nav-media-btn" onClick={navBarOnClick}>
            <div className="nav-media-btn-content">
              <div className={!navDropClick ? "nav-border nav-border-up-close":"nav-border nav-border-up-open"}></div>
              <div className={!navDropClick ? "nav-border nav-border-middle-close":"nav-border nav-border-middle-open"}></div>
              <div className={!navDropClick ? "nav-border nav-border-down-close":"nav-border nav-border-down-open"}></div>
            </div>
          </div>

          <ul className={!navDropClick ? "link-content-close" : "link-content"}>
            <li>
              <Link to="header-container" spy={true} smooth={true} offset={50} duration={500}>ANASAYFA</Link>
            </li>
            <li>
              <Link to="about-container" spy={true} smooth={true} offset={50} duration={500}>HAKKIMIZDA</Link>
            </li>
            <li>
              <Link to="portfolio-container" spy={true} smooth={true} offset={50} duration={500}>PORTFOLYO</Link>
            </li>
            <li>
              <Link to="our_services-container" spy={true} smooth={true} offset={50} duration={500}>HİZMETLERİMİZ</Link>
            </li>
            <li>
              <Link to="customer-container" spy={true} smooth={true} offset={50} duration={500}>MÜŞTERİ MEMNUNİYETİ</Link>
            </li>
            <li>
              <Link to="contact-container" spy={true} smooth={true} offset={50} duration={500}>İLETİŞİM</Link>
            </li>
            {/* <li><button>Portal'a Git</button></li> */}
          </ul>


        </div>
      </div>

    </div>
  )
}

export default Navbar