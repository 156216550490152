import React, { useState } from 'react'
import "./Footer.css";
import images from '../../../Assets/Images/images';

function Footer() {
  const [hoverFace, setHoverFace] = useState(false)
  const [hoverGoogle, setHoverGoogle] = useState(false)

  function handleMouseHoverFace() {
    setHoverFace(true)
  }
  function handleMouseLeaveFace() {
    setHoverFace(false)
  }

  function handleMouseHoverGoogle() {
    setHoverGoogle(true)
  }
  function handleMouseLeaveGoogle() {
    setHoverGoogle(false)
  }


  return (
    <div className='footer-container' id='footer-container'>
      <div className='footer-Content'>
        <p>© 2023 Avcı Oto. Tüm hakları saklıdır...</p>

        <div className='footer-link'>
          <a title='Facebook' href="https://www.facebook.com/OtoavciMH/" target='blank' onMouseEnter={handleMouseHoverFace} onMouseLeave={handleMouseLeaveFace}><img src={hoverFace ? images.facebookIcon : images.facebookIconDark} alt="facebook" /></a>


          <a title='Google' href="https://www.google.com/search?sca_esv=0517884da1645327&sca_upv=1&sxsrf=ADLYWIIXKasfAUOg5w5eDnmf3vCH8GrnSw%3A1718285823180&q=Avc%C4%B1oto%20(Mustafa%20Avc%C4%B1)&stick=H4sIAAAAAAAAAONgU1I1qDA0SU40NEy1SDJLSzJOMTe2MqiwNElMM7ZIMUg2NDYwSDMwWsQq4ViWfGRjfkm-goZvaXFJYlqiAlhEEwDLUcsSRAAAAA&mat=CaeLDF4GFBx1&ved=2ahUKEwiDyvPA2diGAxXtVvEDHaL9BVAQrMcEegQICRAD" target='blank' onMouseEnter={handleMouseHoverGoogle} onMouseLeave={handleMouseLeaveGoogle}> <img src={hoverGoogle ? images.googleIcon : images.googleIconDark} alt="Google" /></a>
        </div>

      </div>
    </div>
  )
}

export default Footer