import React, { createContext, useState, useEffect, useContext } from 'react';


const GoogleMapsContext = createContext();

const GoogleMapsProvider = ({ children }) => {
    const [isApiReady, setIsApiReady] = useState(false);

    useEffect(() => {
        if (window.google && window.google.maps) {
            setIsApiReady(true);
        } else {
            const handleApiLoaded = () => {
                setIsApiReady(true);
            };

            window.initMap = handleApiLoaded;

            // Remove the event listener when the component is unmounted
            return () => {
                window.initMap = null;
            };
        }
    }, []);

    return (
        <GoogleMapsContext.Provider value={{ isApiReady }}>
            {children}
        </GoogleMapsContext.Provider>
    );
};
const useGoogleMaps = () => {
    const context = useContext(GoogleMapsContext);
    if (!context) {
        throw new Error('useGoogleMaps must be used within a GoogleMapsProvider');
    }
    return context;
};
export { GoogleMapsContext, GoogleMapsProvider, useGoogleMaps };