import React from 'react'
import "./Home.css";
import About from "../../../Components/Avci_oto_web/About/About";
import Contact from "../../../Components/Avci_oto_web/Contact/Contact";
import Customer from "../../../Components/Avci_oto_web/Customer/Customer";
import Footer from "../../../Components/Avci_oto_web/Footer/Footer";
import Header from "../../../Components/Avci_oto_web/Header/Header";
import Navbar from "../../../Components/Avci_oto_web/Navbar/Navbar";
import OurServices from "../../../Components/Avci_oto_web/Our_services/Our_services";
import Portfolio from "../../../Components/Avci_oto_web/Portfolio/Portfolio";
import OurFeatures from '../../../Components/Avci_oto_web/Our_Features/Our_features';
import WebContext from '../../../Context/WebSideContext';

function Home() {


  return (

    <div className='web-container-layout' id='web-container-layout'>
      <WebContext.Provider value='context e ulaşıldı'>
      <Navbar />
      <Header />
      <About />
      <Portfolio />
      <OurServices />
      <OurFeatures />
      <Customer />
      <Contact />
      <Footer />
      </WebContext.Provider>
    </div>

  )
}

export default Home