import React, { useRef, useEffect, useState } from 'react';
import { useGoogleMaps } from '../../Context/GoogleMapsContext';
import GoogleReviews from './GoogleReviews';


const GoogleMap = ({ center, zoom }) => {
    const [reviews, setReviews] = useState([]);

    const { isApiReady } = useGoogleMaps();
    const mapRef = useRef(null);

    const fetchPlaceDetails = (map, placeId) => {
        const service = new window.google.maps.places.PlacesService(map);
        const request = {
            placeId: placeId,
            fields: ['name', 'rating', 'reviews'],
        };

        service.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setReviews(place.reviews);
            } else {
                console.error('Error fetching place details:', status);
            }
        });
    };

    useEffect(() => {
        if (!isApiReady || !mapRef.current) return;

        const map = new window.google.maps.Map(mapRef.current, {
            center,
            zoom,
        });

        new window.google.maps.Marker({
            position: center,
            map: map,
        });

        // Replace PLACE_ID with the actual place ID you want to fetch reviews for
        fetchPlaceDetails(map, 'ChIJcz37tugRyhQRAg8wwdA4r5Q');
    }, [isApiReady, center, zoom]);

    return (
        <>
            {isApiReady ? (
                <div style={{ display: "none" }} ref={mapRef} />
            ) : (
                <div style={{ display: "none" }}>Loading Google Maps...</div>
            )}
            <GoogleReviews reviews={reviews} />
        </>

    );
};

export default GoogleMap;