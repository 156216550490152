import logo from "./logo.webp";
import headerBg from "./header-bg.webp";
import brake from "./brake-disc.webp";
import engine from "./car-engine.webp";
import fault from "./fault.webp";
import tire from "./tire.webp";
import strap from "./watch-strap.webp";
import funnel from "./funnel.webp";
import arrow from "./share.webp";
import arrowDark from "./share-dark.webp";
import brakeDark from "./brake-disc-dark.webp";
import engineDark from "./car-engine-dark.webp";
import faultDark from "./fault-tolerant-design-dark.webp";
import tireDark from "./car-service-dark.webp";
import strapDark from "./conveyor-belt-dark.webp";
import funnelDark from "./oil-filter-dark.webp";
import aboutKeysImg from "./about-keys-img.webp";
import portfolioLeader from "./leadership.webp";
import portfolipEnterprise from "./enterprise.webp";
import portfolioLego from "./portfolyo-lego.webp";
import openPlus from "./open.webp";
import ourFeatures from "./our-feature.webp";
import ourFeaturesTwo from "./our-feature-two.webp";
import ourFeaturesThree from "./our-feature-three.webp";
import ourFeaturesFour from "./our-feature-four.webp";
import customerStar from "./star.webp";
import callIcon from "./call.webp";
import emailIcon from "./email.webp";
import locationIcon from "./location-pin.webp";
import callIconDark from "./cellIconDark.webp";
import emailIconDark from "./emailIconDark.webp";
import locationIconDark from "./locationIconDark.webp";
import facebookIcon from "./facebook.webp";
import facebookIconDark from "./facebook-dark.webp";
import googleIcon from "./google.webp";
import googleIconDark from "./google-dark.webp";
import headerMobileBg from "./header-mobile.webp";

const images = {
  logo,
  headerBg,
  brake,
  engine,
  fault,
  tire,
  strap,
  funnel,
  brakeDark,
  engineDark,
  faultDark,
  tireDark,
  strapDark,
  funnelDark,
  arrow,
  arrowDark,
  aboutKeysImg,
  portfolioLeader,
  portfolipEnterprise,
  portfolioLego,
  openPlus,
  ourFeatures,
  ourFeaturesTwo,
  ourFeaturesThree,
  ourFeaturesFour,
  customerStar,
  callIcon,
  emailIcon,
  locationIcon,
  callIconDark,
  emailIconDark,
  locationIconDark,
  facebookIcon,
  facebookIconDark,
  googleIcon,
  googleIconDark,
  headerMobileBg,
};

export default images;
