import React, { useState } from 'react';
import images from '../../Assets/Images/images';

const HoverableDiv = ({children, lightImg, darkImg, className }) => {
  const [hovered, setHovered] = useState(false);


  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const imgSrc = hovered ? darkImg : lightImg;

 

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
    >

      <img src={imgSrc} alt="hoverable" />
      {children}
      <button><img src={hovered ? images.arrowDark : images.arrow} alt="hoverable" /></button>

    </div>
  );
};

export default HoverableDiv;